// MainTabContent.jsx
import React from 'react';
import { SERVER_URL, SERVICE_NAME, SERVICE_NAME_CHAT } from './constants';
import { IconButton, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ChatIcon from '@mui/icons-material/Chat';
import SchoolIcon from '@mui/icons-material/School';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import DescriptionIcon from '@mui/icons-material/Description';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EmailIcon from '@mui/icons-material/Email';
import GavelIcon from '@mui/icons-material/Gavel';
import ReplyIcon from '@mui/icons-material/Reply';
import TimelineIcon from '@mui/icons-material/Timeline';
import TodayIcon from '@mui/icons-material/Today';
import DraftsIcon from '@mui/icons-material/Drafts';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CampaignIcon from '@mui/icons-material/Campaign';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CalculateIcon from '@mui/icons-material/Calculate';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VerifiedIcon from '@mui/icons-material/Verified';
import EventIcon from '@mui/icons-material/Event';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CodeIcon from '@mui/icons-material/Code';
import PsychologyIcon from '@mui/icons-material/Psychology';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PortraitIcon from '@mui/icons-material/Portrait';
import SpeedIcon from '@mui/icons-material/Speed';

const TaskBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: 'center',
  width: 135,
  padding: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const MainContent = ({ startTask, showDashboard, toggleDashboard }) => {

    const tasks = [
        { description: 'Chat', Icon: ChatIcon },
        { description: 'Learn About ' + SERVICE_NAME, Icon: SchoolIcon },
        { description: 'Get Live Help', Icon: LiveHelpIcon },
        { description: 'View Dashboard', Icon: SpeedIcon, action: toggleDashboard },
        { description: 'Resume a Previous Project', Icon: RestartAltIcon },
        { description: 'Write a Report', Icon: DescriptionIcon },
        { description: 'Write Code', Icon: CodeIcon },
        { description: 'Review a Contract', Icon: RateReviewIcon },
        { description: 'Research a Topic', Icon: SearchIcon },
        { description: 'Analyze a Document', Icon: PsychologyIcon },
        { description: 'Write a Business Plan', Icon: BusinessIcon },
        { description: 'Write a Novel', Icon: MenuBookIcon },
        { description: 'Brainstorm an Idea', Icon: LightbulbIcon },
        { description: 'Write an Email', Icon: EmailIcon },
        { description: 'Write a Legal Brief for a Client', Icon: GavelIcon },
        { description: 'Customize a Recipe', Icon: RestaurantIcon },
        { description: 'Write a Response to a Legal Document', Icon: ReplyIcon },
        { description: 'Make a Long-Term Plan', Icon: TimelineIcon },
        { description: 'Make a Daily Plan', Icon: TodayIcon },
        { description: 'Draft a Proposal', Icon: DraftsIcon },
        { description: 'Analyze a Market Trend', Icon: TrendingUpIcon },
        { description: 'Prepare a Financial Forecast', Icon: InsertChartIcon },
        { description: 'Develop a Marketing Strategy', Icon: CampaignIcon },
        { description: 'Conduct a Risk Assessment', Icon: VerifiedIcon },
        { description: 'Compile a Data Report', Icon: DataUsageIcon },
        { description: 'Negotiate a Business Deal', Icon: HandshakeIcon },
        { description: 'Perform a Cost-Benefit Analysis', Icon: CalculateIcon },
        { description: 'Design a Product Roadmap', Icon: EditRoadIcon },
        { description: 'Plan a Project Timeline', Icon: ScheduleIcon },
        { description: 'Evaluate a Resume', Icon: PortraitIcon },
        { description: 'Implement a Quality Control Procedure', Icon: VerifiedIcon },
        { description: 'Facilitate a Training Session', Icon: SchoolIcon },
        { description: 'Organize a Corporate Event', Icon: EventIcon },
        { description: 'Evaluate Employee Performance', Icon: AssessmentIcon },
        { description: 'Create a Budget Plan', Icon: AttachMoneyIcon },
        { description: 'Plan a Trip', Icon: FlightTakeoffIcon },
        { description: 'Outline a Business Case', Icon: NextPlanIcon },
        { description: 'Set up a Strategic Meeting', Icon: MeetingRoomIcon },
        { description: 'Structure a Partnership Agreement', Icon: PeopleAltIcon },
        { description: 'Coordinate a Team Building Activity', Icon: GroupWorkIcon },
        { description: 'Upload Documents for Analysis', Icon: UploadFileIcon },
        { description: 'Add an Activity to this Menu', Icon: AddIcon },
    ];
    
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', padding: 0 }}>
      {tasks.map((task, index) => (
        <TaskBox key={index} onClick={task.action || (() => startTask(task.description))}>
          <task.Icon sx={{ fontSize: '4em', color: 'primary.main', pt: 2 }} />
          <Typography variant="body2" sx={{ mt: 1, color: 'primary.main', fontSize: '0.8rem' }}>
            {task.description}
          </Typography>
        </TaskBox>
      ))}
    </Box>    
  );
};

export default MainContent;


