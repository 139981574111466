// PageLoadingPlaceholder.jsx

import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import './PageLoadingPlaceholder.css';

const PageLoadingPlaceholder = ({ message = 'Loading...' }) => {
  return (
    <div className="page-loading-placeholder">
      <div className="chatlayout_container">
        <div className="chat-container">
          <Backdrop
            sx={{
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              zIndex: 2000,
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            open={true}
          >
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <Typography variant="h6" component="div" style={{ marginBottom: '20px' }}>
                {message}
              </Typography>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </div>
    </div>
  );
};

export default PageLoadingPlaceholder;

