// === LoginPage.jsx ===

import React, { useState, useEffect } from 'react';
import { WRITE_COOKIE_EXPLICITLY } from './constants';
import PublicPage from './PublicPage';
import { Typography, Box, Button, TextField, IconButton, Link, Divider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import qs from 'qs';
import { SERVER_URL, SERVICE_NAME, SERVICE_NAME_CHAT } from './constants';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GoogleLogin } from '@react-oauth/google';                  // 2024-09-10-H
import { useAuth } from './AuthContext';                            // 2024-09-10-H

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const { handleGoogleLogin } = useAuth();                          // 2024-09-10-H
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const handleLogin = () => {
    console.log("Starting login...");
    setLoading(true);
    setLoadingMessage("Logging in...");
    setLoginError("");

    axios.post(SERVER_URL, qs.stringify({
      email: email,
      password: password,
      action: 'authenticate_user'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        console.log("Got a login response.");
        const data = response.data;
        console.log("data:", data);
        // 2024-07-22-I: we don't want ANY error to break login. 
        // if (data.error) {
        //   console.error('Login Error:', data.error);
        //   setLoginError("Login failed. Please check your email and password.");
        // } else {
          console.log('data.login_successful: ', data.login_successful);
          if (data.login_successful == 1) {
            const sessionid = response.data.sessionid;
            console.log('sessionid: ', sessionid);
            //
            // 2024-08-13-B: setting of cookie is automatic when the server sends it.
            // and is more secure.
            // If we have problems, we can turn WRITE_COOKIE_EXPLICITLY on for a cookie
            // that is a little easier to create but is less secure.
            //
            if(WRITE_COOKIE_EXPLICITLY && sessionid) {
                console.log("Writing cookie explicitly...");
                setCookie('sessionid', sessionid, 7);
            } else {
                console.log("Not writing cookie explicitly...");
            }
            
            console.log("Writing a separate login cookie");
            setCookie('logged_in', 1, 7);                   // coordinate expirations?
            
            // note: http only cookies do not appear in document.cookie, but are sent automatically
            //  for axios and fetch requests.
            //console.log("Cookies:", document.cookie);
            
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
              navigate('/chat');
            }, 500);
          } else {
            setLoginError("Login failed. Please check your email and password.");
          }
        // }
      })
      .catch(error => {
        console.error('Network Error:', error);
        setLoading(false);
        setLoginError("Network error. Please check your connection and try again.");
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <PublicPage publicPageTitle="Log In" minHeight="400px" maxWidth="600px">
      <Backdrop sx={{ color: '#fff', zIndex: 2000 }} open={loading}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h6" component="div" style={{ marginBottom: '20px' }}>
            {loadingMessage}
          </Typography>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
      <Snackbar
        open={showSuccessMessage}
        message="Login Successful"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      {/*<Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}> */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', py: { xs: 2, sm: 3, md: 4 } }}> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <Box sx={{ width: '100%', maxWidth: '450px', mx: 4 }}>

          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => {
              console.log('Google Login Failed');
            }}
          />
          <Divider sx={{ my: 2 }}>OR</Divider>
          <Typography variant="body1" align="center" sx={{ fontSize: '0.9rem', mb: 2 }}>
            Use an Existing Ripley Guru Account
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            sx={{ mb: 2, fontSize: '1.2rem' }}
            InputProps={{ style: { fontSize: '1.2rem' } }}
            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
          />
          <TextField
            margin="dense"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            sx={{ mb: 2, fontSize: '1.2rem' }}
            InputProps={{
              style: { fontSize: '1.2rem' },
              endAdornment: (
                <IconButton onClick={togglePasswordVisibility} tabIndex={-1}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
          />
          {loginError && (
            <div style={{ color: 'red', marginBottom: '10px', fontSize: '1.1rem' }}>{loginError}</div>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" size="large" onClick={handleLogin} sx={{ mt: 2, mb: 2, fontSize: '1.2rem' }}>
              Log In
            </Button>
          </Box>
          <Typography variant="body1" align="center" sx={{ fontSize: '1.2rem' }}>
            New User? <Link href="#" onClick={handleSignUpClick} sx={{ fontSize: '1.2rem' }}>Sign Up</Link>
          </Typography>
        </Box>
      </Box>
    </PublicPage>
  );
};

export default LoginPage;
























