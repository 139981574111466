// SignUpPage.jsx
import React, { useState } from 'react';
import PublicPage from './PublicPage';
import { Typography, Box, Button, TextField, IconButton, Link, Divider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import qs from 'qs';
import { SERVER_URL } from './constants';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GoogleLogin } from '@react-oauth/google';                  // 2024-09-10-H
import { useAuth } from './AuthContext';                            // 2024-09-10-H

const SignUpPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const { handleGoogleLogin } = useAuth();                          // 2024-09-10-H

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailBlur = () => {
    if (email && !isValidEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordBlur = () => {
    if (password && !isPasswordStrong(password)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSignUp = () => {
    console.log("Starting signup...");
    if (password !== confirmPassword) {
      console.log('Passwords do not match!');
      setPasswordError(true);
      return;
    }

    setLoading(true);
    setLoadingMessage("Signing up...");
    setSignupError("");
    setEmailError(false);
    setPasswordError(false);

    axios.post(SERVER_URL, qs.stringify({
      email: email,
      password: password,
      action: 'register_user'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        const data = response.data;
        console.log("data: ", data);
        if(data.signup_success === 1 || data.signup_success === "1") {
            console.log('Signup Successful');
            navigate('/validate_email');
        } else {
            console.log("Signup not successful");
            console.log("data.error_code", data.error_code);
            let errorMessage;
            if(data.error_code === "100") {
                errorMessage = "An account with this email already exists."; // get a password reminder.
            } else {
                errorMessage = "There was an error creating your account.";
            }
            setSignupError(errorMessage);
        }
      })
      .catch(error => {
        console.error('Network Error:', error);
        setLoading(false);
        setSignupError("Network error. Please check your connection and try again.");
      });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordStrong = (password) => {
    const minLength = 8;
    const hasLetter = /[A-Za-z]/.test(password);
    const hasNumber = /\d/.test(password);
    return password.length >= minLength && hasLetter && hasNumber;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <PublicPage publicPageTitle="Sign Up" minHeight="400px" maxWidth="600px">
      <Backdrop sx={{ color: '#fff', zIndex: 2000 }} open={loading}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h6" component="div" style={{ marginBottom: '20px' }}>
            {loadingMessage}
          </Typography>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <Box sx={{ width: '100%', maxWidth: '450px', mx: 4 }}>
          
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => {
              console.log('Google Login Failed');
            }}
          />
          <Divider sx={{ my: 2 }}>OR</Divider>          
          <Typography variant="body1" align="center" sx={{ fontSize: '0.9rem', mb: 2 }}>
            Create a New Ripley Guru Account
          </Typography>          
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            error={emailError}
            helperText={emailError && 'Please enter a valid email address'}
            sx={{ mb: 2, fontSize: '1.2rem' }}
            InputProps={{ style: { fontSize: '1.2rem' } }}
            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
          />
          <TextField
            margin="dense"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
            error={passwordError}
            helperText={passwordError && 'Password must be at least 8 characters long and include a letter and a number'}
            sx={{ mb: 2, fontSize: '1.2rem' }}
            InputProps={{
              style: { fontSize: '1.2rem' },
              endAdornment: (
                <IconButton onClick={togglePasswordVisibility} tabIndex={-1}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
          />
          <TextField
            margin="dense"
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={password !== confirmPassword}
            helperText={password !== confirmPassword && 'Passwords do not match'}
            sx={{ mb: 2, fontSize: '1.2rem' }}
            InputProps={{ style: { fontSize: '1.2rem' } }}
            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
          />
          {signupError && (
            <div style={{ color: 'red', marginBottom: '10px', fontSize: '1.1rem' }}>{signupError}</div>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="large"
              onClick={handleSignUp}
              disabled={emailError || passwordError || password !== confirmPassword}
              sx={{ mt: 2, mb: 2, fontSize: '1.2rem' }}
            >
              Sign Up
            </Button>
          </Box>
          <Typography variant="body1" align="center" sx={{ fontSize: '1.2rem' }}>
            Existing User? <Link href="#" onClick={handleLoginClick} sx={{ fontSize: '1.2rem' }}>Login</Link>
          </Typography>
        </Box>
      </Box>
    </PublicPage>
  );
};

export default SignUpPage;










