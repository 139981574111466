// export const SERVER_URL = process.env.NODE_ENV === 'production'
//  ? 'https://ripley.guru/request'
//   : 'http://192.168.0.12:81/request';
export const SERVER_URL = process.env.NODE_ENV === 'production'
 ? 'https://ripley.guru/request'
  : 'http://192.168.0.109/request';
// export const SERVER_URL = process.env.NODE_ENV === 'production'
//  ? 'https://ripley.guru/request'
//   : 'http://192.168.0.12/request';   
  
//export const DO_BURSTS = process.env.NODE_ENV === 'production' ? true : false;
export const DO_BURSTS = true;
//export const SERVER_URL = 'http://192.168.0.12:81/request';
export const SERVICE_NAME = 'Ripley Guru';
export const SERVICE_NAME_CHAT = 'RG';
export const SERVICE_NAME_LOGO = 'ripley.guru';
export const COMPANY_NAME = 'Performance Technology Intelligence Programs, LLC';
export const CHAT_PAGE_URL = 'https://shylock.ripley.guru:3000/chat';
export const SPLASH_PAGE_URL = 'https://shylock.ripley.guru:3000/';
export const MAX_PASTE_LENGTH = 5000; 
//export const WRITE_COOKIE_EXPLICITLY = process.env.NODE_ENV === 'production' ? false : true;
export const WRITE_COOKIE_EXPLICITLY = false;
export const GOOGLE_CLIENT_ID = '232511160919-m410dvlt4ttspbev1tfdkh84eskq5loj.apps.googleusercontent.com';







