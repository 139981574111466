// === PublicPage.jsx ===

import React, { useState, useEffect } from 'react';
import { COMPANY_NAME, SERVICE_NAME_LOGO, SERVER_URL } from './constants';
import { Typography, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import logoIcon from './logo_icon.png';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './Layout.css';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import ReactMarkdown from 'react-markdown';
import { useAuth } from './AuthContext'; 

const CustomListItem = styled(ListItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: 'gray',         // never happens?
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'gray',          // also never happens
  },
}));

const SplashContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(13, 8, 0),
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#edf2fa',
  backgroundImage: `
    linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05) 76%, transparent 77%, transparent),
    linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05) 76%, transparent 77%, transparent)
    `,
  backgroundSize: '50px 50px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(8, 2, 0),
  },  
}));

const PublicPageContainer = styled(Box)(({ theme, minHeight, maxWidth  }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: 'auto',
  minHeight: minHeight || 'auto',
  maxWidth: maxWidth || 1200,
  marginBottom: theme.spacing(8),
  padding: theme.spacing(2),
  backgroundColor: '#f8fafc',
  border: '1px solid #ddd',
  borderRadius: '5px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  overflowY: 'auto',
  marginLeft: theme.spacing(3),
  fontFamily: 'Roboto, sans-serif',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3), // Reduce top margin for medium screens
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.down('msm')]: { // Use the new custom breakpoint
    marginTop: theme.spacing(2), // Further reduce top margin for medium-small screens
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    marginRight: 0,
    padding: theme.spacing(1.5),
  },
  flex: 1,
  flexDirection: 'column',  
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.down('msm')]: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LogoIcon = styled('img')(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  marginRight: theme.spacing(2),
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const LogoText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontFamily: 'Lato, sans-serif',
  fontSize: '1.8rem',
  color: '#1565c0',
}));

const HamburgerContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1000,
}));

const PublicPage = ({ pageName, publicPageTitle, children, minHeight, maxWidth }) => {

    const [pageTitle, setPageTitle] = useState(publicPageTitle || '');
    const [pageContent, setPageContent] = useState(null);
    const [loading, setLoading] = useState(!!pageName);

    const { isLoggedIn, handleLogout } = useAuth();

    const fetchPageData = (pageName) => {
      return new Promise((resolve, reject) => {
        console.log("Fetching public page data...");
    
        axios.post(SERVER_URL, qs.stringify({
          action: 'load_public_page',
          page_name: pageName,
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          withCredentials: true
        })
        .then(response => {
          console.log("Got a response.");
          const data = response.data;
          console.log("data:", data);
    
          if (data.title && data.content) {
            console.log("Received public page data");
            resolve(data); // Resolve the promise with the received data
          } else {
            console.log("Did NOT receive public page data");
            reject(new Error("Public page data not found")); // Reject the promise with an error
          }
        })
        .catch(error => {
          console.error('Network Error:', error);
          reject(error); // Reject the promise with the error
        });
      });
    };

    useEffect(() => {
      if (pageName) {
        fetchPageData(pageName)
          .then(data => {
            setPageTitle(data.title);
            setPageContent(data.content);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching public page data:', error);
            setLoading(false);
          });
      }
    }, [pageName]);
  
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

    const copyrightSymbol = String.fromCodePoint(0x00A9);
    const markdownStyles = {
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        color: '#134a7e',
      },
      '& h1, & h2': {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),          
      },
      '& h3, & h4, & h5, & h6': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),          
      },      
      '& ul, & ol': {
        paddingLeft: theme.spacing(4),
      },
    };

  return (
    <SplashContainer>
      <Link to="/" style={{ textDecoration: 'none' }}>
      <div className="logo-container">
        <img src={logoIcon} alt="RG Logo" className="logo" />
      </div>
      </Link>
      <Link to="/" style={{ textDecoration: 'none' }}>      
      <div className="logo-text">
        <b>{SERVICE_NAME_LOGO}</b>
      </div>
      </Link>

      <TitleContainer>
        <Typography
          variant="h3"
          sx={{
            color: '#134a7e',
            fontFamily: 'Lato, sans-serif',
            fontSize: '4rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '2rem',
            },
          }}
        >
          {pageTitle}
        </Typography>
      </TitleContainer>

      <PublicPageContainer minHeight={minHeight} maxWidth={maxWidth}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {pageContent ? (
                <div style={{ display: 'block', width: '100%' }}>
                <Box sx={markdownStyles}>
                <ReactMarkdown>
                    {pageContent}
                </ReactMarkdown>
                </Box>
                </div>
            ) : (
              children
            )}
          </Box>
        )}
      </PublicPageContainer>

      <div className="hamburger-container">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
            <CustomListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
              <ListItemText primary="Home"/>
            </CustomListItem>

            {!isLoggedIn && (
            <CustomListItem button component={Link} to="/signup" onClick={toggleDrawer(false)}>
              <ListItemText primary="Sign Up"/>
            </CustomListItem>
            )}

            <CustomListItem button component={Link} to="/pricing" onClick={toggleDrawer(false)}>
              <ListItemText primary="Pricing"/>
            </CustomListItem>

            <CustomListItem button component={Link} to="/tos" onClick={toggleDrawer(false)}>
              <ListItemText primary="Terms and Conditions"/>
            </CustomListItem>

            <CustomListItem button component={Link} to="/privacy" onClick={toggleDrawer(false)}>
              <ListItemText primary="Privacy Policy"/>
            </CustomListItem>
            { /*
            <CustomListItem button component={Link} to="/contactus" onClick={toggleDrawer(false)}>
              <ListItemText primary="Contact Us"/>
            </CustomListItem>
            */}
            {!isLoggedIn && (
            <CustomListItem button component={Link} to="/login" onClick={toggleDrawer(false)}>
              <ListItemText primary="Login"/>
            </CustomListItem>          
            )}
            {isLoggedIn && (
            <CustomListItem 
              button 
              onClick={() => { 
                toggleDrawer(false)(); 
                setTimeout(() => {
                  handleLogout(); 
                }, 200); 
              }}
            >
              <ListItemText primary="Log Out"/>
            </CustomListItem>
            )}            

        </List>
      </Drawer>

      <Box
        sx={{
          width: '100%',
          mt: 15,
          py: 1,
          mb: 1,
          textAlign: 'center',
          position: 'relative',
          bottom: 0,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {copyrightSymbol} {new Date().getFullYear()} {COMPANY_NAME}
        </Typography>
      </Box>
    </SplashContainer>
  );
};

export default PublicPage;






